.bento-grid {
  padding: 1rem;

}

.bento-grid-colored-headline {
  font-size: 1.5rem;
  color: var(--active-color);
  margin: 0;
}


.bento-grid-item {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  cursor: pointer;
  position: relative;
  gap: 1rem;
  overflow: hidden;
}

.bento-grid-item p {
  margin: 0;
  font-weight: 600;
  max-width: 100%;
}

.bento-grid-item svg {
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: 1.25rem;
  right: 1rem;
  color: var(--primary-color-transparent);
}

.bento-grid-fullscreen {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
}

.bento-grid-fullscreen-content {
    width: 100%;
    max-width: 600px;
    position: relative;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 1rem;
}


.bento-grid-fullscreen-content p {
    font-weight: 600;
    margin-top: 1rem;
}

.bento-grid-fullscreen-content .description-image-container {
    background: #fafafa;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bento-grid-fullscreen-content .description-image {
    width: 100%;
}

.bento-grid-fullscreen-content:has(img) {
    padding: 1rem;
}

.bento-grid-fullscreen-content img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: .5rem;
}

.bento-grid-fullscreen button {
    position: absolute;
    top: 1rem;
    right: 2rem;
  }

  .bento-grid-fullscreen-content svg {
    width: 1.5rem;
    height: 1.5rem;
    position: absolute;
    top: 1.5rem;
    right: 1rem;
    color: var(--primary-color-transparent);
  }

  @media(max-width: 992px) {
    .bento-grid-colored-headline {
        font-size: 1rem;
    }
    .bento-grid-item svg {
        top: 1rem;
    }
}

.fixed-border-radius {
    border-radius: 1rem;
}

.ellipsis-content {
  margin: 0;
  font-weight: 600;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-width: 100%;
}

.expanded-content {
  overflow: visible;
  display: block;
  -webkit-line-clamp: unset;
  line-clamp: unset;
  -webkit-box-orient: unset;
  white-space: normal;
}
