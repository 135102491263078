.timeline-outer-wrapper {
  margin: 3rem auto;
  width: 100%;
}
.timeline-inner-wrapper {
  position: relative;
  padding-left: 4rem;
  margin: 0 0 0 0.75rem;
  color: var(--secondary-color);
}
.timeline-inner-wrapper:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 4px;
  height: 100%;
  background: var(--secondary-color);
}
.timeline-inner-wrapper .timeline-container {
  position: relative;
  margin-bottom: 2.5rem;
}
.timeline-inner-wrapper .timeline-container .timeline-icon {
  position: absolute;
  left: -88px;
  top: 4px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  text-align: center;
  background: var(--secondary-color);
}
.timeline-inner-wrapper .timeline-container .timeline-icon svg {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: var(--active-color);
  font-size: 1.5rem;
}
.timeline-inner-wrapper .timeline-container .timeline-icon img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.timeline-inner-wrapper .timeline-container .timeline-body {
  background: var(--secondary-color);
  border-radius: var(--button-border-radius);
  padding: 20px 20px 15px;
  box-shadow: 1px 3px 9px rgba(0, 0, 0, 0.1);
}

.timeline-inner-wrapper .timeline-container .timeline-body .timeline-title {
  margin: 0;
}
.timeline-inner-wrapper
  .timeline-container
  .timeline-body
  .timeline-title
  .badge {
  color: var(--tertiary-color);
  font-size: 1rem;
  text-transform: uppercase;
  font-weight: 800;
}
.timeline-inner-wrapper .timeline-container .timeline-body .timeline-subtitle {
  font-weight: 400;
  font-style: italic;
  color: var(--primary-color);
  margin-top: 1rem;
  font-size: 0.75rem;
}

@media (max-width: 1050px) {
  .timeline-inner-wrapper {
    position: relative;
    padding-left: 2rem;
  }
  .timeline-inner-wrapper .timeline-container .timeline-icon {
    left: -3.5rem;
  }
  .timeline-outer-wrapper {
    margin: 1rem;
  }
}

@media (min-width: 1050px) {
  .timeline-inner-wrapper .timeline-container .timeline-body:before {
    content: "";
    background: inherit;
    width: 1.25rem;
    height: 1.25rem;
    display: block;
    position: absolute;
    left: -0.625rem;
    transform: rotate(45deg);
    border-radius: 0 0 0 2px;
  }
}

.timeline-body ul {
  padding-left: 0rem;
}

.timeline-body ul li {
  list-style-type: none;
  margin-bottom: 1rem;
}

.toggle-expand-btn {
  background-color: transparent;
  padding: 0;
  color: var(--active-color);
  margin-top: 1rem;
}

/* ✅ Default: Show Only 2 Lines */
.timeline-description {
  overflow: hidden;
  display: -webkit-box;
  line-clamp: 2;
  -webkit-line-clamp: 2; /* ✅ Limit to 2 lines */
  -webkit-box-orient: vertical;
  transition: all 0.3s ease-in-out;
}

/* ✅ Expanded: Show Full Content */
.timeline-description.expanded {
  line-clamp: unset;
  -webkit-line-clamp: unset; /* Remove line clamping */
  height: auto;
}
