.cta {
    margin: 1rem;
}

.cta-container {
    align-items: flex-start;
    border: 2px solid var(--secondary-color);
    border-radius: var(--button-border-radius);
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
    margin: 0 auto;
    padding: 1rem;
    padding-bottom: 2rem;
}

.cta-container p {
    margin-top: 0;
}