.usercard-container {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.usercard-body {
  border-radius: var(--button-border-radius);
  border: 2px solid var(--secondary-color);
  padding: 1.5rem;
  margin: 1rem 1rem 1rem 1rem;
  z-index: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow: hidden;
  height: 2rem;
  transition: all ease .5s;
}

.usercard-body.visible {
  z-index: 1;
  margin: -5rem 1rem 1rem 1rem;
  height: 100%;
  box-shadow: rgba(var(--image-shadow), 0.75) 0px -50px 50px 0px
}

.usercard-image {
  z-index: 1;
  flex: 0 0 25%;
}

.usercard-image img {
  border-radius: 50%;
  aspect-ratio: 1 / 1;
  width: 100%;
  max-width: 250px;
  border: 2px solid var(--primary-color);
  filter: grayscale(1);
}

 
.usercard-body ul {
  padding-left: 0;
}

.usercard-body ul li {
  list-style-type: none;
  margin-bottom: .5rem;
}

.usercard-title {
  display: flex;
  align-items: center;
  width: 100%;
}

.usercard-title > div {
  margin-left: auto;
}


@media (max-width: 1050px) {
  .usercard-container {
    flex-direction: column;
  }
}